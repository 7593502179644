const useRebootSystem = () => {
  const reboot = async () => {
    const ports = [5000, 5001];
    const savedPort = localStorage.getItem("rebootPort");
    const tryPort = async (port: number): Promise<boolean> => {
      const url = `http://127.0.0.1:${port}/reboot`;
      try {
        const response = await fetch(url);
        if (response.ok) {
          localStorage.setItem("rebootPort", port.toString());
          return true;
        }
      } catch (error) {
        // Ignore and try the next port
      }
      return false;
    };

    if (savedPort) {
      const portNumber = parseInt(savedPort, 10);
      const success = await tryPort(portNumber);
      if (success) return;
      const otherPort = ports.find((port) => port !== portNumber);
      if (otherPort) {
        const successOther = await tryPort(otherPort);
        if (successOther) return;
      }
    } else {
      for (const port of ports) {
        const success = await tryPort(port);
        if (success) return;
      }
    }

    throw new Error("Error rebooting system");
  };
  return { reboot };
};

export default useRebootSystem;
