import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Webcam, { WebcamProps } from "react-webcam";
import useGetLocker from "../../hooks/useGetLocker";
import useIdleTimer from "../../hooks/useIdleTimer";
import { useLocation } from "react-router-dom";
import * as Sentry from "@sentry/react";

type MediaError = string | DOMException | undefined;

interface CameraContextProps {
  capture: () => string | null | undefined;
  mediaError: MediaError;
  isEnabled: boolean;
}

const CameraContext = createContext<CameraContextProps | null>(null);

const WEBCAM_CONFIG = {
  screenshotFormat: "image/jpeg",
  muted: false,
  style: { position: "absolute", right: "100%" } as const,
} as WebcamProps;

const CAMERA_IDLE_TIMEOUT = 30000; // 30 seconds

export function CameraProvider({ children }: { children: React.ReactNode }) {
  const webcamRef = useRef<Webcam & HTMLVideoElement>(null);
  const [mediaError, setMediaError] = useState<MediaError>();
  const [isActive, setIsActive] = useState(true); // Start active by default
  const lockerQuery = useGetLocker();
  const location = useLocation();

  const isEnabled = Boolean(lockerQuery?.data?.config.webcam_enabled);
  const isHomePage = location.pathname === "/";

  const capture = useCallback(() => {
    if (!isEnabled || !isActive) return null;

    try {
      return webcamRef.current?.getScreenshot();
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  }, [isEnabled, isActive]);

  // Handle user activity
  useEffect(() => {
    const handleActivity = () => {
      if (isEnabled) {
        setIsActive(true);
      }
    };

    document.addEventListener("pointerdown", handleActivity);
    document.addEventListener("keydown", handleActivity);
    document.addEventListener("mousemove", handleActivity);

    return () => {
      document.removeEventListener("pointerdown", handleActivity);
      document.removeEventListener("keydown", handleActivity);
      document.removeEventListener("mousemove", handleActivity);
    };
  }, [isEnabled]);

  // Auto-stop camera on idle, but only if on home page
  useIdleTimer(CAMERA_IDLE_TIMEOUT, () => {
    if (isHomePage) {
      setIsActive(false);
    }
  });

  // Keep camera active on home page
  useEffect(() => {
    if (!isHomePage && isEnabled) {
      setIsActive(true);
    }
  }, [isHomePage, isEnabled]);

  return (
    <CameraContext.Provider
      value={{
        capture,
        mediaError,
        isEnabled,
      }}>
      {children}

      {isEnabled && isActive && (
        <Webcam
          {...WEBCAM_CONFIG}
          ref={webcamRef}
          onUserMediaError={setMediaError}
          mirrored={true}
        />
      )}
    </CameraContext.Provider>
  );
}

export const useCamera = () => {
  const context = useContext(CameraContext);
  if (!context) {
    throw new Error("useCamera must be used within a CameraProvider");
  }
  return context;
};
