import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useOpenDoor from "../../hooks/useOpenDoor";

interface Props {
  show: boolean;
  onHide: () => any;
  door?: string;
}

const WARN_CLICKS = 10;
const MAX_CLICKS = 20;

export function DoorStuckHelpModal({ show, onHide, door }: Props) {
  const { t } = useTranslation();
  const openDoor = useOpenDoor();
  const [clicks, setClicks] = useState(0);

  return (
    <Modal show={show} onHide={onHide} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <i className="bi-question-circle me-2"></i>
          {t("common.help")}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          <strong>{t("faq.doorStuck.title")}</strong>
        </p>

        <p className={clicks >= MAX_CLICKS ? "text-muted" : ""}>
          {t("faq.doorStuck.description")}
        </p>

        <div className="d-flex justify-content-center my-3">
          <Button
            size="lg"
            disabled={clicks >= MAX_CLICKS}
            onPointerUp={async () => {
              if (door) await openDoor.trigger(door);
              setClicks(clicks + 1);
            }}>
            {t("faq.doorStuck.reopen")}
          </Button>
        </div>
        {clicks >= WARN_CLICKS && (
          <p className="pt-2">{t("faq.doorStuck.description2")}</p>
        )}
      </Modal.Body>
    </Modal>
  );
}
