import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { BrowserRouter, MemoryRouter } from "react-router-dom";
import useSunCycleTheme from "../../hooks/useSunCycleTheme";
import { ToastProvider } from "../Toast";
import "./i18n";
import Router from "./Router";

import "./app.scss";
import "./dark.scss";

function App() {
  let RouterProvider = MemoryRouter;
  if (process.env.NODE_ENV === "development") RouterProvider = BrowserRouter;

  useSunCycleTheme();

  return (
    <ToastProvider>
      <QueryClientProvider
        client={
          new QueryClient({
            defaultOptions: {
              queries: {
                refetchOnWindowFocus: false,
                refetchOnReconnect: false,
                networkMode: "always",
                onError(err) {
                  Sentry.withScope((scope) => {
                    scope.setLevel("error");
                    Sentry.captureException(err);
                  });
                },
              },
              mutations: {
                networkMode: "always",
                onError(error, variables, context) {
                  Sentry.withScope((scope) => {
                    scope.setLevel("error");
                    Sentry.captureException(error, {
                      tags: {
                        variables: JSON.stringify(variables),
                        context: JSON.stringify(context),
                      },
                    });
                  });
                },
              },
            },
          })
        }>
        <ReactQueryDevtools initialIsOpen={false} />
        <RouterProvider>
          <Router />
        </RouterProvider>
      </QueryClientProvider>
    </ToastProvider>
  );
}

export default App;
