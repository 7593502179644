import { useEffect, useState, useMemo } from "react";
import "./Snow.css";

const checkIsSnowing = () => {
  const now = new Date();
  const currentMonth = now.getMonth();
  const currentDate = now.getDate();
  return (
    (currentMonth === 11 && currentDate >= 1) || // December 1st to December 31st
    (currentMonth === 0 && currentDate <= 15) // January 1st to January 15th
  );
};

// Custom hook to handle snowflake logic
const useSnowflakes = () => {
  // Memoize the initial snow check to avoid recalculation
  const isSnowing = useMemo(() => checkIsSnowing(), []);
  const [snowflakes, setSnowflakes] = useState<JSX.Element[]>([]);

  useEffect(() => {
    if (!isSnowing) return;

    const generateSnowflakeStyle = () => ({
      left: `${Math.random() * 100}%`,
      animationDuration: `${5 + Math.random() * 10}s`,
      width: `${Math.random() * 6 + 4}px`,
      height: `${Math.random() * 6 + 4}px`,
    });

    const createSnowflake = () => (
      <div
        key={Date.now() + Math.random()}
        className="snowflake"
        style={generateSnowflakeStyle()}
      />
    );

    const interval = setInterval(() => {
      setSnowflakes((prev) => [...prev.slice(-49), createSnowflake()]);
    }, 200);

    return () => clearInterval(interval);
  }, [isSnowing]); // Only re-run if isSnowing changes

  // Return early if not snowing
  if (!isSnowing) return [];
  return snowflakes;
};

export const Snow = () => {
  const snowflakes = useSnowflakes();

  if (snowflakes.length === 0) return null;

  return (
    <div className="snow-container" data-testid="snow-container">
      {snowflakes}
    </div>
  );
};
