import { Footer } from "components/Footer";
import { Header } from "components/Header";
import useOnTimeout from "hooks/useOnTimeout";
import useOpenDoor from "hooks/useOpenDoor";
import useUpdateReservation from "hooks/useUpdateReservation";
import { useCallback, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

enum Action {
  Continue,
  Terminate,
}

interface ChoiceCardProps {
  title: string;
  description: string;
  selected: boolean;
  onSelect: () => void;
}

const ChoiceCard = ({
  title,
  description,
  selected,
  onSelect,
}: ChoiceCardProps) => {
  const cardClass = `rounded bg-surface text-on-surface shadow px-5 py-4 ${
    selected ? "border border-2 border-primary shadow-none" : ""
  }`;

  return (
    <div className={cardClass} onPointerUp={onSelect}>
      <h2 className="fw-light mb-2">{title}</h2>
      <p>{description}</p>
    </div>
  );
};

export default function WithdrawConfirm() {
  const [action, setAction] = useState<Action>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const reservation = location.state.reservation;
  const openDoor = useOpenDoor();

  const counter = useOnTimeout(45, () => navigate("/"));

  const handleSuccess = useCallback(async () => {
    if (reservation.door?.position) {
      await openDoor.trigger(reservation.door.position);
    }
    navigate("/withdraw-success", {
      state: { reservation, action: "withdraw" },
    });
  }, [navigate, reservation, openDoor]);

  const updateReservation = useUpdateReservation({ onSuccess: handleSuccess });

  const handleAction = useCallback(() => {
    if (action === Action.Continue) {
      handleSuccess();
    } else {
      updateReservation.mutate({ id: reservation.id, withdraw: true });
    }
  }, [action, handleSuccess, updateReservation, reservation.id]);

  return (
    <div className="position-absolute h-100 w-100 top-0 left-0">
      <Header title={t("withdrawConfirm.title")} />

      <Container className="mt-5 p-3 w-75 text-center">
        <div className="mb-5">
          <h2>
            {t("withdrawConfirm.hello")}, {reservation.first_name}{" "}
            {reservation.last_name}.
          </h2>
          <p className="lead">{t("withdrawConfirm.choose")}</p>
        </div>

        <Row className="mb-5">
          <Col>
            <ChoiceCard
              selected={action === Action.Continue}
              onSelect={() => setAction(Action.Continue)}
              title={t("withdrawConfirm.continue")}
              description={t("withdrawConfirm.continueDescription")}
            />
          </Col>
          <Col>
            <ChoiceCard
              onSelect={() => setAction(Action.Terminate)}
              selected={action === Action.Terminate}
              title={t("withdrawConfirm.terminate")}
              description={t("withdrawConfirm.terminateDescription")}
            />
          </Col>
        </Row>

        <Button
          size="lg"
          className="fs-3"
          disabled={action === undefined}
          onPointerUp={handleAction}>
          {t("withdrawConfirm.open")}
        </Button>
      </Container>

      <Footer
        left={
          <Button
            variant="link"
            className="fs-3 text-decoration-none"
            onPointerUp={() => navigate("/")}>
            <i className="bi-house-door-fill me-2"></i>
            {t("common.home")} ({counter})
          </Button>
        }
      />
    </div>
  );
}
