import { createRef, useEffect, useState } from "react";
import { Button, Container, Modal, ToggleButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Webcam from "react-webcam";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import useGetLocker from "../../hooks/useGetLocker";
import useGetReservations from "../../hooks/useGetReservations";
import useOpenDoor from "../../hooks/useOpenDoor";
import { getDownlinkSpeedInMbps, getUserIPAddress } from "./helpers";

function Test() {
  const [selectedCol, setSelectedCol] = useState<string>("");
  const [ipAddress, setIPAddress] = useState<string | null>(null);
  const [downLink, setDownlink] = useState<number | null>(null);
  const [isOpeningDoor, setIsOpeningDoor] = useState<boolean>(false);
  const [currentDoor, setCurrentDoor] = useState<string | null>(null);
  const [isWebcamFullScreen, setIsWebcamFullScreen] = useState<boolean>(false);
  const [showTypeButtons, setShowTypeButtons] = useState<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const webcamRef = createRef<Webcam & HTMLVideoElement>();
  const openDoor = useOpenDoor();
  const lockerQuery = useGetLocker({ refetchOnMount: false });

  const reservationQuery = useGetReservations(
    { active: true },
    { enabled: false }
  );

  const delay = () => new Promise((resolve) => setTimeout(resolve, 2750));

  useEffect(() => {
    getUserIPAddress().then((ip) => setIPAddress(ip));

    const downLinkSpeed = getDownlinkSpeedInMbps();
    setDownlink(downLinkSpeed);
  }, []);

  const fetchReservationList = () => {
    reservationQuery.refetch();
    localStorage.setItem("reservationListLastFetch", Date.now().toString());
  };

  const sendSyncMessage = () => {
    navigator?.serviceWorker?.controller?.postMessage({
      type: "REPLAY_QUEUE",
    });
  };

  const triggerDoorsFromCol = async () => {
    setIsOpeningDoor(true);
    for (const row of cols[selectedCol]) {
      setCurrentDoor(selectedCol + row[0]);
      await openDoor.trigger(selectedCol + row[0]);
      await delay();
    }
    setCurrentDoor(null);
    setIsOpeningDoor(false);
  };

  const cols =
    lockerQuery.data?.doors?.reduce(
      (acc, door: Door) => {
        const col = door.position[0];
        if (!(col in acc)) acc[col] = [];
        acc[col].push([door.position.substring(1), door.dimension]);
        acc[col] = acc[col].sort((a, b) => {
          const aNum = parseInt(a[0]);
          const bNum = parseInt(b[0]);
          if (aNum < bNum) return -1;
          if (aNum > bNum) return 1;
          return 0;
        });
        return acc;
      },
      {} as { [key: string]: [string, string][] }
    ) || {};

  const uniqueDimensions = Array.from(
    lockerQuery.data?.doors?.reduce((acc: Set<string>, door: Door) => {
      acc.add(door.dimension);
      return acc;
    }, new Set<string>()) || new Set<string>()
  );

  function triggerDoorsFromType(doorType: string) {
    setIsOpeningDoor(true);
    const doorsToOpen = lockerQuery.data?.doors
      ?.filter((door) => door.dimension === doorType)
      .map((door) => door.position);

    const openDoorSequentially = async (positions: string[]) => {
      for (const position of positions) {
        setCurrentDoor(position);
        await openDoor.trigger(position);
        await delay();
      }
      setCurrentDoor(null);
      setIsOpeningDoor(false);
    };

    if (doorsToOpen && doorsToOpen.length > 0) {
      openDoorSequentially(doorsToOpen);
    } else {
      console.warn("No doors found of the specified dimension type.");
      setIsOpeningDoor(false);
    }
  }

  return (
    <div className="position-absolute h-100 w-100 top-0 left-0">
      <Header title={t("test.title")} />

      <Container className="mt-4">
        <Button className="me-3" size="lg" variant="outline" disabled>
          <i className="bi bi-globe me-2" />
          {ipAddress || t("test.ipUnavailable")}{" "}
          {downLink && `(${downLink} mbps)`}
        </Button>
        <Button
          className="me-3"
          size="lg"
          onPointerUp={() => setIsWebcamFullScreen(true)}
          disabled={!lockerQuery.data?.config?.webcam_enabled}>
          <i className="bi-camera-fill me-2" />{" "}
          {lockerQuery.data?.config?.webcam_enabled
            ? t("test.showWebcam")
            : t("test.webcamDisabled")}
        </Button>
      </Container>

      <Container className="mt-3 p-0">
        <Container>
          <h1 className="fw-light fs-3 mb-3">{t("test.open")}</h1>

          {Object.keys(cols).map((col) => (
            <ToggleButton
              id={`col-${col}`}
              type="checkbox"
              key={col}
              checked={selectedCol === col}
              value={col}
              variant="outline-primary"
              onChange={() => setSelectedCol(col)}
              className="me-3"
              size="lg">
              {t("test.column")} {col}
            </ToggleButton>
          ))}
        </Container>

        <Container
          className="mt-3"
          style={{ maxHeight: "180px", overflowX: "auto" }}>
          {selectedCol &&
            cols[selectedCol].map((row) => (
              <Button
                key={row[0]}
                className="me-3 mb-3"
                size="lg"
                disabled={isOpeningDoor && currentDoor !== selectedCol + row[0]}
                variant={
                  currentDoor === selectedCol + row[0] ? "danger" : "primary"
                }
                onPointerUp={() => openDoor.trigger(selectedCol + row[0])}>
                {selectedCol}
                {row[0]} ({t([`common.${row[1]}`, row[1]])})
              </Button>
            ))}
          {selectedCol && (
            <Button
              className="me-3 mb-3"
              size="lg"
              disabled={isOpeningDoor}
              onPointerUp={() => triggerDoorsFromCol()}>
              {t("test.triggerAll")}
            </Button>
          )}
        </Container>

        <section className="mt-4">
          <ToggleButton
            value="toggleDoorTypes"
            id="toggle-door-types"
            type="checkbox"
            checked={showTypeButtons}
            onChange={() => setShowTypeButtons((prev) => !prev)}
            variant="outline-primary"
            size="lg">
            {t("test.triggerAllPerDimension")}
          </ToggleButton>

          {showTypeButtons && (
            <div className="mt-3">
              {uniqueDimensions.map((dimension) => (
                <Button
                  key={dimension}
                  className="me-3 mb-3 capitalize"
                  size="lg"
                  onClick={() => triggerDoorsFromType(dimension)}>
                  {t("test.type")}
                  {"  "}
                  {dimension}
                </Button>
              ))}
            </div>
          )}
        </section>
      </Container>
      <Container className="mt-3 p-0">
        <Container>
          <h1 className="fw-light fs-3">{t("test.offlineMode")}</h1>
          <Button
            className="me-3"
            size="lg"
            onPointerUp={() => sendSyncMessage()}>
            {t("test.syncQueue")}
          </Button>
          <Button size="lg" onPointerUp={() => fetchReservationList()}>
            {t("test.fetchReservationList")}
          </Button>
        </Container>
      </Container>

      <Modal
        show={isWebcamFullScreen}
        onHide={() => setIsWebcamFullScreen(false)}
        centered
        size="lg"
        scrollable={false}
        backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Webcam</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            className="mx-auto d-block"
            style={{ width: "95%", borderRadius: "10px" }}
            mirrored={true}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onPointerUp={() => setIsWebcamFullScreen(false)}>
            {t("common.close")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Footer
        left={
          <Button
            variant="link"
            className="fs-3 text-decoration-none"
            onPointerUp={() => navigate(-1)}>
            <i className="bi-arrow-left-circle-fill me-2"></i>
            {t("help.back")}
          </Button>
        }
      />
    </div>
  );
}

export default Test;
