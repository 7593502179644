import * as Sentry from "@sentry/react";

export async function openDoor(position: string) {
  const x = position[0];
  const y = position.substring(1);
  const url = `http://127.0.0.1:5000/?xpos=${x}&ypos=${y}`;

  try {
    const response = await fetch(url);
    return response.ok;
  } catch (error: unknown) {
    const message = error instanceof Error ? error.message : "Unknown error";
    const enhancedError = new Error(
      `Failed to open door ${position}: ${message}`
    );
    enhancedError.cause = error;
    throw enhancedError;
  }
}

export default function useOpenDoor() {
  const trigger = async (position: string) => {
    try {
      await openDoor(position);
    } catch (error) {
      // Log to Sentry with context
      Sentry.withScope((scope) => {
        scope.setTag("door_position", position);
        scope.setLevel("error");
        Sentry.captureException(error);
      });
      console.error("Door controller error:", error);
    }
  };

  return { trigger };
}
