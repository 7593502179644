import { createRef } from "react";
import { Route, Routes } from "react-router";
import { useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import useMaintenance from "../../hooks/useMaintenance";
import { AddRecipient } from "../../pages/AddRecipient";
import { CancelReservation } from "../../pages/CancelReservation";
import { CarrierValidation } from "../../pages/CarrierValidation";
import { CodeProgress } from "../../pages/CodeProgress";
import { CodeSuccess } from "../../pages/CodeSuccess";
import { DepositForm } from "../../pages/DepositForm";
import { DepositSuccess } from "../../pages/DepositSuccess";
import { Help } from "../../pages/Help";
import { Home } from "../../pages/Home";
import { InvalidCode } from "../../pages/InvalidCode";
import { Maintenance } from "../../pages/Maintenance";
import { RecipientForm } from "../../pages/RecipientForm";
import { RecipientsConfirmation } from "../../pages/RecipientsConfirmation";
import { RecipientPassword } from "../../pages/RecipientsPassword";
import { SelectDoor } from "../../pages/SelectDoor";
import { SelectRecipient } from "../../pages/SelectRecipient";
import { Test } from "../../pages/Test";
import { WithdrawConfirm } from "../../pages/WithdrawConfirm";
import { WithdrawSuccess } from "../../pages/WithdrawSuccess";
import { useDefaultLanguage } from "./useDefaultLanguage";
import { useRedirectHomeOnIdle } from "./useRedirectHomeOnIdle";
import { useRefreshWhenStale } from "./useRefreshWhenStale";
import * as Sentry from "@sentry/react";
import { CameraProvider } from "components/Camera/Camera";

export default function Router() {
  const { maintenance, offline } = useMaintenance();
  const nodeRef = createRef<any>();
  const location = useLocation();

  useDefaultLanguage();
  useRedirectHomeOnIdle();
  useRefreshWhenStale();
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  if (maintenance) return <Maintenance />;

  return (
    <CameraProvider>
      <TransitionGroup>
        <CSSTransition
          nodeRef={nodeRef}
          key={location.key}
          classNames="fade"
          timeout={250}>
          <div ref={nodeRef}>
            <SentryRoutes location={location}>
              <Route
                path="/cancel-reservation"
                element={<CancelReservation />}
              />
              <Route
                path="/recipients-password"
                element={<RecipientPassword />}
              />
              <Route path="/recipient-form" element={<RecipientForm />} />
              <Route path="/code-progress" element={<CodeProgress />} />
              <Route path="/code-success" element={<CodeSuccess />} />
              <Route path="/deposit-success" element={<DepositSuccess />} />

              <Route path="/withdraw-success" element={<WithdrawSuccess />} />
              <Route path="/withdraw-confirm" element={<WithdrawConfirm />} />
              <Route path="/select-door" element={<SelectDoor />} />
              <Route
                path="/recipients-confirmation"
                element={<RecipientsConfirmation />}
              />
              <Route path="/add-recipient" element={<AddRecipient />} />
              <Route path="/deposit-form" element={<DepositForm />} />
              <Route path="/select-recipient" element={<SelectRecipient />} />
              <Route path="/invalid-code" element={<InvalidCode />} />
              <Route
                path="/carrier-validation"
                element={<CarrierValidation />}
              />
              <Route path="/help" element={<Help />} />
              <Route path="/Test" element={<Test />} />
              <Route path="/" element={<Home offline={offline} />} />
            </SentryRoutes>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </CameraProvider>
  );
}
